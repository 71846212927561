import React from 'react'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import Container from '../components/container'

import styles from '../components/globals.module.css'

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (window.location.pathname === '/our-people/vivek-ramaswami/') {
        window.location = '/our-people/';
      }
    }
  }

  render() {
    return (
      <Layout>
        <SEO title='404: Not found' />

        <Hero hideNext="true">
          Not Found
        </Hero>

        <section className={styles.offwhite}>
          <Container>
            <p className={`${styles.clear} ${styles.space_and_center}`}>No results found.</p>
          </Container>
        </section>

      </Layout>
    )
  }
}

export default NotFoundPage
